// @note needs to be synced with the backend function
// BookingService->getBookingActionLabel() && BookingActionEnum

const bookingActionEnum = [
  {
    label: 'Verstuur verzoek om door te gaan',
    value: 'request',
  },
  {
    label: 'Accepteer om door te gaan',
    value: 'accept',
  },
  {
    label: 'Doe prijs voorstel',
    value: 'negotiation-price'
  },
  {
    label: 'Betaling nodig',
    value: 'payment-fee-rent',
  },
  {
    label: 'Fee betaling nodig',
    value: 'payment-fee',
  },
  {
    label: 'Betaling nodig',
    value: 'payment-rent',
  },
];

export default bookingActionEnum
