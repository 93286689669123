import React from 'react';
import App from './App';
import {createRoot} from 'react-dom/client';
import './scss/main.scss'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://58af65336cd8f29d5a667ae3b61e5caa@o4508648541257728.ingest.de.sentry.io/4508648787476560",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "standplaats.nl",
    "backend.standplaats.nl",
    "dev.standplaats.nl",
    "dev-backend.standplaats.nl"
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);
