import React, {useEffect, useState} from "react";
import {localhost} from '../../env'
import {useNavigate, useParams} from "react-router-dom";
import useBookingService from "../../api/services/useBookingService";
import BookingActions from "../../components/BookingActions/BookingActions";
import useFunction from "../../hooks/useFunction";
import useBooking from "../../hooks/useBooking";
import usePrice from "../../hooks/usePrice";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import arrowSimpleRight from "../../assets/icons/arrow-simple-right.svg"
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import standIcon from "../../assets/icons/stand.svg"
import yesIcon from "../../assets/icons/yes.svg"
import noIcon from "../../assets/icons/no.svg"
import companyIcon from "../../assets/icons/company.svg"
import './style.scss'

function UserBookings() {

  const {c, date, dateTime, truncate} = useFunction()
  const {getBookingStatusLabel, getBookingActionLabel} = useBooking()
  const {userShow} = useBookingService()
  const [bookings, setBookings] = useState(null)
  // const [calendarDates, setCalendarDates] = useState([]) //todo, if calendarDates is nbever used, why need state?
  const [isLoading, setIsLoading] = useState(true)
  const [formHeight, setFormHeight] = useState(0)
  const {bookingId} = useParams()
  const navigate = useNavigate()
  const {userAction} = useBookingService()


  const [filters, setFilters] = useState({
    receiving: true,
    requesting: true,
    statusRequested: true,
    statusAccepted: true,
    statusRejected: false,
    statusPaid: true,
    statusCanceled: false,
    statusCompleted: false,
    date: null,
    company: null,
    orderBy: 'date', //date, company
  })

  const {
    formatPrice,
    // getBookingPriceTotalWithoutFeeAndOptions,
    // getBookingOptionsTotalPrice,
    // getBookable,
    // getBookingFeeAndTransactionFee,
    // getBookingPriceTotal,
    // getBookingPriceTotalWithoutFee,
    // addBookingFee,
    // isPositivePrice: isPositivePriceFunc,
    transactionFee,
  } = usePrice()

  //concept, requested, accepted, rejected, paid, canceled, completed, invalid

  useEffect(() => {
    loadBookings(bookingId)
  }, [])


  useEffect(() => {
    if (!isLoading) {
      setFormHeight(document.getElementById('bookings-container').clientHeight)  //todo use useRef: const inputRef = useRef(null);  ref={inputRef};  access via     console.log('inputRef', inputRef.current);?
    }
  }, [isLoading])

  /**
   * Load bookings
   */
  const loadBookings = (bookingId = null) => {
    !isLoading && setIsLoading(true)
    setBookings([])
    userShow(
      (bookings) => {
        setBookings(bookings)
        setIsLoading(false)
      },
      () => {
        //todo
      },
      bookingId
    )
  }

  /**
   *
   * @param key
   */
  const handleFilterSwitch = (key) => {
    setFilters(prevState => {
        return {
          ...prevState,
          [key]: !filters[key]
        }
      }
    )
  }


  const callbackConfirm = (booking, action, denyReason) => {
    setIsLoading(true)
    userAction(
      (data) => {

        /*
         * Action remove
         */
        if (action === 'remove') {
          if (bookings){
            const objWithIdIndex = bookings.findIndex((obj) => obj.id === booking.id);
            if (objWithIdIndex > -1) {
              bookings.splice(objWithIdIndex, 1);
            }
          }
          setBookings(bookings)
        } else {

          /*
           * Action cancel-request
           */
          //todo use more nice method...
          loadBookings()
        }
        setIsLoading(false)
      },
      (error) => {
        c('booking update error', error)
      },
      booking.id,
      {
        action: action,
        deny_reason: denyReason,
      },
    )
  }

  // /**
  //  *
  //  * @param dates
  //  */
  // const handleCalendarDates = (dates) => {
  //   setCalendarDates(dates)
  // }
  //
  // /**
  //  *
  //  * @param key
  //  * @returns {string}
  //  */
  // const getFilterSwitchClass = (key) => {
  //   return (filters[key]) ? 'btn' : 'btn-ghost'
  // }

  /**
   * //todo should be DRY
   * @param booking
   * @returns {boolean}
   */
  const isFiltered = (booking) => {
    if (!filters.receiving && booking.direction === 'receiving') {
      return true
    }
    if (!filters.requesting && booking.direction === 'requesting') {
      return true
    }

    if (!filters.statusRequested && booking.status === 'requested') {
      return true
    }
    if (!filters.statusAccepted && booking.status === 'accepted') {
      return true
    }
    if (!filters.statusRejected && booking.status === 'rejected') {
      return true
    }
    if (!filters.statusPaid && booking.status === 'paid') {
      return true
    }
    if (!filters.statusCanceled && booking.status === 'canceled') {
      return true
    }
    if (!filters.statusCompleted && booking.status === 'completed') {
      return true
    }
    return false
  }


  // /**
  //  *
  //  * @param booking
  //  * @returns {JSX.Element}
  //  */
  // const getActionButtons = (booking) => {
  //
  //
  //
  // }

  // /**
  //  *
  //  * @param date
  //  * @param amountBooked
  //  * @returns {JSX.Element}
  //  */
  // const calendarDay = (date, amountBooked) => {
  //   const newDate = new DateObject(date)
  //   return (
  //     <div className="calendar-day">
  //       <div className="date">{newDate.format("D")}</div>
  //       <div className="amount-booked">{amountBooked}</div>
  //     </div>
  //   )
  // }

  const BookingItem = (booking, i) => {
    if (isFiltered(booking)) {
      return null
    }
    let className = "booking booking-status-" + booking.status
    className += (booking.needs_action) ? ' needs-action ' : ''


    // const isPositivePrice = isPositivePriceFunc(booking, getBookable(booking))


    //todo this is not correct
    // const isPositivePrice = true


    return (
      <div key={i} className={className}>
        <div className="fields">
          <div className="field id">
            <p className={"label"}>ID:</p>
            <p>{booking.booking_number}</p>
            {localhost && <p>ID: {booking.id}</p>}
          </div>
          <div className="field status">
            <p className={"label"}>Status:</p>
            <h4>
              {getBookingStatusLabel(booking.status)}
              {booking.updated_after_request_at && booking.status === 'requested'
                && <span className="meta"> (geupdate)</span>
              }
            </h4>
            <h4>
              {booking.status === 'concept' && <>Nog af te maken</>}
              {(booking.status === 'requested' || booking.status === 'accepted') &&
                <>
                  {booking.needs_action ? getBookingActionLabel(booking.needs_action) : 'Wachten op tegenpartij'}
                </>
              }
              {booking.status === 'paid' && <>Gepland!</>}
            </h4>
            <p className={"label"}>Richting:</p>
            <p>{(booking.direction === 'receiving') ? 'Inkomend' : 'Uitgaand'}</p>
            {(booking.type === 'spot-group-requests-stand') &&
              <div className="row">
                <img width={22} src={spotGroupIcon} alt="Locatie"/>
                <img width={22} src={arrowSimpleRight} alt="->"/>
                <img width={22} src={standIcon} alt="Stand / Truck"/>
              </div>
            }
            {(booking.type === 'stand-requests-spot') &&
              <div className="row">
                <img width={22} src={standIcon} alt="Stand / Truck"/>
                <img width={22} src={arrowSimpleRight} alt="->"/>
                <img width={22} src={spotGroupIcon} alt="Locatie"/>
              </div>
            }
          </div>

          <div className="field dates">
            <p className={"label"}>Data: </p>
            {booking.date_ranges && !!booking.date_ranges.length && booking.date_ranges.map((dateRange, i) => {
              return (
                <div key={i}>
                  {dateRange.end_date ?
                    <p>{date(dateRange.start_date)} <span
                      className="meta">t/m</span> {date(dateRange.end_date)}</p>
                    :
                    <p>{date(dateRange.start_date)}</p>
                  }
                </div>
              )
            })}
          </div>

          {'spots' in booking && booking.spots && !!booking.spots.length &&
            <div className="field spots">
              <p className="label">Spots:</p>
              <div className="spots-container">
                {booking.spots.map((spot, i) => {
                  if (spot.spot_cluster){
                    return (<div key={i}>{spot.spot_cluster.label}: {spot.label}</div>)
                  } else {
                    return (<div key={i}>{spot.label}</div>)
                  }
                })}
              </div>
            </div>
          }

          <div className="field options">
            <p className="label">Opties:</p>
            {booking.booking_options && !!booking.booking_options.length && booking.booking_options.map((option, i) => {


              //todo deze Opties worden nu niet weergegeven (oud script?)


              return (
                <div key={i}>
                  <p>{option.bookable_option.label}</p>
                </div>
              )
            })}
          </div>

          {(booking.status === 'concept') ?
            <>
              {/*{booking.direction === 'receiving' &&*/}
              {/*  <div className="field price">*/}
              {/*    <p className="label">*/}
              {/*      {isPositivePrice ? 'Inkomsten: ' : 'Kosten/Uitgaven: '}*/}
              {/*      (kunnen nog wijzigen)</p>*/}
              {/*    <p>Verhuur: {formatPrice(getBookingPriceTotalWithoutFeeAndOptions(booking, getBookable(booking)))}</p>*/}
              {/*    <p>Opties: {formatPrice(getBookingOptionsTotalPrice(booking))}</p>*/}
              {/*    <p>Totaal (ex. BTW): {formatPrice(getBookingPriceTotalWithoutFee(booking, getBookable(booking)))}</p>*/}
              {/*  </div>*/}
              {/*}*/}
              {/*{booking.direction === 'requesting' &&*/}
              {/*  <div className="field price">*/}
              {/*    {isPositivePrice ?*/}
              {/*      <>*/}
              {/*        <p className="label">Kosten/Uitgaven: (kunnen nog wijzigen)</p>*/}
              {/*        <p>Huur: {formatPrice(addBookingFee(getBookingPriceTotalWithoutFeeAndOptions(booking, getBookable(booking))))}</p>*/}
              {/*        <p>Opties: {formatPrice(getBookingOptionsTotalPrice(booking))}</p>*/}
              {/*        <p>Boekingskosten: {formatPrice(transactionFee)}</p>*/}
              {/*        <p>Totaal (ex. BTW): {formatPrice(getBookingPriceTotal(booking, getBookable(booking)))}</p>*/}
              {/*      </>*/}
              {/*      :*/}
              {/*      <>*/}
              {/*        <p className="label">Inkomsten: (kunnen nog wijzigen)</p>*/}
              {/*        <p>Huur: {formatPrice(getBookingPriceTotalWithoutFeeAndOptions(booking, getBookable(booking)))}</p>*/}
              {/*        <p>Opties: {formatPrice(getBookingOptionsTotalPrice(booking))}</p>*/}
              {/*        <p>Totaal: {formatPrice(getBookingPriceTotalWithoutFee(booking, getBookable(booking)))}</p>*/}
              {/*        <p className="label">Kosten/Uitgaven: (kunnen nog wijzigen)</p>*/}
              {/*        <p>Fee &*/}
              {/*          Boekingskosten: {formatPrice(getBookingFeeAndTransactionFee(booking, getBookable(booking)))}</p>*/}
              {/*        <p className="label">Totaal: (kan nog wijzigen)</p>*/}
              {/*        <p>Totaal (ex. BTW): {formatPrice(getBookingPriceTotal(booking, getBookable(booking)))}</p>*/}
              {/*      </>*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*}*/}
            </>
            :
            <>
              {booking.direction === 'receiving' && booking.prices &&
                <div className="field price">
                  <h5>{booking.prices.totals.receiving.total.get ? 'Inkomsten:' : 'Uitgaven:'}</h5>
                  {booking.prices.totals.receiving.get.rent
                    ?
                    <p>Verhuur inkomsten: {formatPrice(booking.prices.totals.receiving.get.rent)}</p>
                    :
                    <p>Huur kosten: {formatPrice(booking.prices.totals.receiving.pay.rent)}</p>
                  }
                  <p>Opties inkomsten: {formatPrice(booking.prices.totals.receiving.get.options)}</p>

                  {booking.prices.totals.receiving.total.get
                    ?
                    <p>Totale inkomsten (ex. BTW): {formatPrice(booking.prices.totals.receiving.total.get)}</p>
                    :
                    <p>Totale uitgaven (ex. BTW): {formatPrice(booking.prices.totals.receiving.total.pay)}</p>
                  }

                </div>
              }
              {booking.direction === 'requesting' && booking.prices &&
                <div className="field price">
                  <h5>{booking.prices.totals.requesting.total.get ? 'Inkomsten:' : 'Uitgaven:'}</h5>
                  {booking.prices.totals.requesting.get.rent
                    ?
                    <p>Verhuur inkomsten: {formatPrice(booking.prices.totals.requesting.get.rent)}</p>
                    :
                    <p>Huur kosten: {formatPrice(booking.prices.totals.requesting.pay.rent)}</p>
                  }
                  <p>Opties kosten: {formatPrice(booking.prices.totals.requesting.pay.options)}</p>

                  <p>{(booking.prices.totals.requesting.pay.fees >= transactionFee) ? 'Boekingskosten:' : 'Fee & Boekingskosten:'} {formatPrice(booking.prices.totals.requesting.pay.fees)}</p>

                  {booking.prices.totals.requesting.total.get
                    ?
                    <p>Totale inkomsten (ex. BTW): {formatPrice(booking.prices.totals.requesting.total.get)}</p>
                    :
                    <p>Totale uitgaven (ex. BTW): {formatPrice(booking.prices.totals.requesting.total.pay)}</p>
                  }


                </div>
              }
            </>
          }

          <div className="field spot-group-stand">

            {booking.spot_group ?
              <>
                <p className="label">Locatie:</p>
                <Button
                  color="grey-ghost"
                  size="s"
                  link={'/locatie/' + booking.spot_group.slug}
                  label={truncate(booking.spot_group.label, 20)}
                  target="_blank"
                  icon={spotGroupIcon}
                />
              </>
              :
              <>
                {(booking.type === 'spot-group-requests-stand' && booking.requested_by_company)
                  ?
                  <>
                    <p className="label">Organisatie (en geen locatie-profiel):</p>
                    <Button
                      color="grey-ghost"
                      size="s"
                      link={'/user/berichten/' + booking.requested_by_company_id}
                      label={truncate(booking.requested_by_company.legal_name, 20)}
                      target="_blank"
                      icon={companyIcon}
                    />
                  </>
                  : <>Geen locatie</>
                }
              </>
            }

            <p className="label">Stand:</p>
            {booking.stand ?
              <Button
                color="grey-ghost"
                size="s"
                link={'/stand/' + booking.stand.slug}
                label={truncate(booking.stand.label, 20)}
                target="_blank"
                icon={standIcon}
              />
              :
              <>Nog geen stand</>
            }
          </div>
          <div className="field meta">
            <p className={"label"}>Aangevraagd:</p>
            <p>{dateTime(booking.created_at)}</p>
            <p className={"label"}> Geupdate:</p>
            <p>{dateTime(booking.updated_at)}</p>
          </div>
          <div className="field actions">
            <BookingActions booking={booking} callbackConfirm={callbackConfirm}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="page-user-bookings">
      <h1>Boekingen & Aanvragen</h1>
      {isLoading ?
        <Loading
          height={formHeight}
        />
        :
        <div id="bookings-container">
          <div className="row">
            <Button
              label="Betalingsoverzicht"
              link={'/user/betalingen'}
            />
            <br/>
            <br/>
            <br/>
          </div>
          <div className="filters">
            {bookingId ?
              <Button
                label="Alle boekingen"
                onClick={() => {
                  setBookings(null)
                  navigate('/user/boekingen')
                  loadBookings()
                }}
              />
              :
              <>
                <Button
                  label="Inkomend"
                  onClick={() => handleFilterSwitch('receiving')}
                  color={(filters.receiving) ? 'grey' : 'grey-ghost'}
                  icon={(filters.receiving) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Uitgaand"
                  onClick={() => handleFilterSwitch('requesting')}
                  color={(filters.requesting) ? 'grey' : 'grey-ghost'}
                  icon={(filters.requesting) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Aangevraagd"
                  onClick={() => handleFilterSwitch('statusRequested')}
                  color={(filters.statusRequested) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusRequested) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Geaccepteerd"
                  onClick={() => handleFilterSwitch('statusAccepted')}
                  color={(filters.statusAccepted) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusAccepted) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Betaald & Bevestigd"
                  onClick={() => handleFilterSwitch('statusPaid')}
                  color={(filters.statusPaid) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusPaid) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Afgewezen"
                  onClick={() => handleFilterSwitch('statusRejected')}
                  color={(filters.statusRejected) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusRejected) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Geannuleerd"
                  onClick={() => handleFilterSwitch('statusCanceled')}
                  color={(filters.statusCanceled) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusCanceled) ? yesIcon : noIcon}
                  size="s"
                />
                <Button
                  label="Afgerond"
                  onClick={() => handleFilterSwitch('statusCompleted')}
                  color={(filters.statusCompleted) ? 'grey' : 'grey-ghost'}
                  icon={(filters.statusCompleted) ? yesIcon : noIcon}
                  size="s"
                />
              </>
            }

            {/*<Button*/}
            {/*  label="Status"*/}
            {/*  disabled={true}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*  label="Datum"*/}
            {/*  disabled={true}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*  label="Sorteren"*/}
            {/*  color="grey-ghost"*/}
            {/*  disabled={true}*/}
            {/*/>*/}
          </div>
          <div className="bookings">
            {bookings && Array.isArray(bookings)
              ? !!bookings.length
                ? bookings.map((booking, i) => {
                  return BookingItem(booking, i)
                })
                : <>Nog geen Boekingen</>
              : <></>
            }
            {bookings && !Array.isArray(bookings) &&
              BookingItem(bookings, 0)
            }
          </div>
        </div>
      }
    </div>
  )
}

export default UserBookings
