import React, {useState} from "react";
import PropTypes from "prop-types";
import ReactGA from 'react-ga4';
import {GACode} from "../../env"
import Button from "../Button/Button";
import useSwal from "../../hooks/useSwal";
import useLocationState from "../../hooks/useLocationState";
import useFunction from "../../hooks/useFunction";
import {useNavigate} from "react-router-dom";
import Popup from "../Popup/Popup";


const DenyReasonField = ({booking, reason, onChange}) => {
  return (
    <textarea
      placeholder={booking.direction === 'requesting'
        ? "Geef de rede door aan de ontvanger van deze boeking"
        : "Geef de rede door aan de aanvrager van deze boeking"
      }
      rows={4}
      value={reason || ''} // reason moet een string zijn, fallback naar lege string als deze null/undefined is
      onChange={onChange}
    ></textarea>
  );
};

const BookingActions = ({booking, callbackConfirm, buttonSize = 's'}) => {
  const {popup} = useSwal()
  const {getWizardBookingState} = useLocationState()
  const {dateTime} = useFunction()
  const navigate = useNavigate()
  const [reason, setReason] = useState(""); // Beheer de state zoals gewoonlijk
  const [showReasonPopup, setShowReasonPopup] = useState(false);

  /**
   *
   * @param booking
   * @param action
   */
  const handleAction = (booking, action) => {
    let title, text, buttonCancelLabel, buttonConfirmLabel, denyReason
    switch (action) {
      case 'accept':
        title = 'Accepteren'
        text = ''
        // text = 'De aanvrager ontvangt automatisch een betalingsverzoek. Wanneer de betaling voldaan is dan is de boeking '
        break
      // case 'change':
      //   title = 'Aanpassen'
      //   text = 'De data aanpassen?'
      //   break
      // case 'pay':
      //   title = 'NU Betalen'
      //   text = 'De boeking is direct definitief nadat u met iDeal betaald heeft'
      //   buttonCancel = 'Annuleren'
      //   break
      case 'reject':
        title = 'Boeking afwijzen'
        buttonCancelLabel = 'Niet afwijzen'
        text = 'Deze aanvraag afwijzen?'
        denyReason = true //denied by receiving company
        break
      case 'cancel-request':
        title = 'Aanvraag annuleren!'
        buttonCancelLabel = 'Niet annuleren'
        text = 'Deze aanvraag annuleren?'
        denyReason = true //denied by requesting company
        break;
      case 'cancel-accepted':
        title = 'Geaccepteerde boeking annuleren!'
        buttonCancelLabel = 'Boeking behouden'
        text = 'LET OP: Deze aanvraag is al geaccepteerd. Er zullen kosten in rekening gebracht worden.'
        denyReason = true //denied by either requesting or receiving company
        break;
      case 'cancel-booking':
        title = 'Boeking annuleren?'
        buttonConfirmLabel = 'Boeking toch annuleren!'
        text = 'LET OP: Deze aanvraag is al geaccepteerd. Er zullen kosten in rekening gebracht worden.'
        denyReason = true //denied by either requesting or receiving company
        break;
      case 'remove':
        title = 'Boekingsaanvraag Verwijderen?'
        buttonConfirmLabel = 'Boekingaanvraag Verwijderen!'
        text = 'LET OP: Deze booking verwijderen'
        break;
      default:
        title = 'Onbekend... neem contact op.'
        break;
    }

    popup({
      title: title,
      text: text,
      buttonCancelLabel: buttonCancelLabel,
      buttonConfirmLabel: buttonConfirmLabel,
      callbackConfirm: () => handleCallbackConfirm({
        booking,
        action,
        denyReason,
        title,
        text,
        buttonCancelLabel,
        buttonConfirmLabel
      }),
    })
  }

  const handleCallbackConfirm = (props) => {
    const {booking, action, denyReason, title, text, buttonCancelLabel, buttonConfirmLabel} = props

    //hacky way to pass props. should refactor...
    if (denyReason && !reason) {
      setShowReasonPopup(props)
      return
    }

    callbackConfirm(booking, action, reason)
    GACode && ReactGA.event({
      ...{
        booking_number: booking.booking_number,
        booking_id: booking.id,
        booking_prices: booking.prices,
        booking_status: booking.status,
        booking_type: booking.type,
        booking_requested_by_company_id: booking.requested_by_company_id,
        booking_requested_by_user_id: booking.requested_by_user_id,
        booking_requested_terms_accepted_at: booking.requested_terms_accepted_at,
        booking_received_by_company_id: booking.received_by_company_id,
        booking_received_by_user_id: booking.received_by_user_id,
        booking_received_terms_accepted_at: booking.received_terms_accepted_at,
        booking_updated_after_request_at: booking.updated_after_request_at,
        booking_stand_id: booking.stand_id,
        booking_spot_group_id: booking.spot_group_id,
        booking_direction: booking.direction,
      },
      name: 'booking_' + action,
    });
  }

  if (showReasonPopup) {
    return (
      <Popup
        title="Rede voor afweizing"
        showPopup={!!showReasonPopup}
        setShowPopup={setShowReasonPopup}
        closeButtonLabel={showReasonPopup.buttonCancelLabel}
        postButtons={<Button
          label={(showReasonPopup.buttonConfirmLabel) ? showReasonPopup.buttonConfirmLabel : 'Boeking annuleren!'}
          disabled={!reason}
          className="btn-red"
          onClick={() => handleCallbackConfirm(showReasonPopup)}
        />}
      >
        <DenyReasonField
          booking={booking}
          reason={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Popup>
    )
  }

  if (booking.direction === 'receiving') {
    switch (booking.status) {
      case 'concept':
        return <></>
      case 'requested':
        return <>

          {booking.received_terms_accepted_at ?
            <p>Geaccepteerd op:
              {dateTime(booking.received_terms_accepted_at)}
            </p>
            : booking.needs_action === 'negotiation-price'
              ?
              <>
                <Button
                  label="Prijs voorstel"
                  color="green"
                  size={buttonSize}
                  link={"/wizard/boek/negotiate_offers/" + booking.id}
                />
              </>
              :
              <>
                <Button
                  label="Accepteren"
                  color="green"
                  size={buttonSize}
                  onClick={() => handleAction(booking, 'accept')}
                />
              </>
          }
          <Button
            label="Aanpassen" //change dates before accepting
            color="blue"
            size={buttonSize}
            link={'/wizard/boek/start/' + booking.id}
            linkState={getWizardBookingState()}
          />
          <Button
            label="Afwijzen"
            color="red"
            size={buttonSize}
            onClick={() => handleAction(booking, 'reject')}
          />
        </>
      case 'accepted':
        return <>
          {booking.payments.map((payment, i) => {
            return (
              <Button
                key={i}
                label="Betalen"
                color="green"
                size={buttonSize}
                linkOutside={payment.payment_url}
                target="_blank"
              />
            )
          })}
          <Button
            label="Annuleren*" //already accepted, but cancel anyway..
            color="red-ghost"
            size={buttonSize}
            onClick={() => handleAction(booking, 'cancel-accepted')}
          />
        </>
      case 'rejected':
        return <></>
      case 'paid':
        return <>
          <Button
            label="Annuleren*" //already paid, but cancel anyway..
            color="red-ghost"
            size={buttonSize}
            onClick={() => handleAction(booking, 'cancel-booking')}
          />
        </>
      case 'completed':
        return <></>
      default:
    }
  } else {
    /* booking.direction === requesting */
    switch (booking.status) {
      case 'concept':
        return <>
          <Button
            color="green"
            size={buttonSize}
            label="Doorgaan"
            link={'/wizard/boek/start/' + booking.id}
            linkState={getWizardBookingState()}
          />
          <Button
            color="red"
            size={buttonSize}
            label="Verwijderen"
            onClick={() => handleAction(booking, 'remove')}
          />
        </>
      case 'requested':
        return <>
          {booking.requested_terms_accepted_at ?
            <p>Geaccepteerd op:
              {dateTime(booking.requested_terms_accepted_at)}
            </p>
            :
            booking.needs_action === 'negotiation-price'
              ?
              <>
                <Button
                  label="Prijs voorstel"
                  color="green"
                  size={buttonSize}
                  link={"/wizard/boek/negotiate_offers/" + booking.id}
                />
              </>
              :
              <>
                <Button
                  label="Accepteren"
                  color="green"
                  size={buttonSize}
                  onClick={() => handleAction(booking, 'accept')}
                />
              </>
          }

          <Button
            label="Annuleren" //cancell request
            color="red"
            size={buttonSize}
            onClick={() => handleAction(booking, 'cancel-request')}
          />
          <Button
            label="Aanpassen" //change dates before accepting
            color="red-ghost"
            size={buttonSize}
            onClick={() => {
              popup({
                  title: "Booking Aanpassen?",
                  text: 'De aanvraag is al verzonden, maar deze kan nog aangepast worden omdat hij nog niet geaccepteerd ' +
                    'is. De ontvanger van deze boekingsaanvraag zal een bericht krijgen met de geupdate informatie.',
                  buttonConfirmLabel: 'Aanpassen',
                  callbackConfirm: () => navigate('/wizard/boek/start/' + booking.id, {state: getWizardBookingState()}),
                  buttonConfirmClassname: 'btn-color-grey btn-size-m',
                  buttonCancelClassname: 'btn-color-blue btn-size-m'
                }
              )
            }}
          />
        </>
      case 'accepted':
        return <>
          {booking.payments.map((payment, i) => {
            return (
              <Button
                key={i}
                label="Betalen"
                color="green"
                size={buttonSize}
                linkOutside={payment.payment_url}
                target="_blank"
              />
            )
          })}
          <Button
            label="Annuleren*" //already accepted, but cancel anyway...
            color="red-ghost"
            size={buttonSize}
            onClick={() => handleAction(booking, 'cancel-accepted')}
          />
        </>
      case 'rejected':
        return <></>
      case 'paid':
        return <>
          <Button
            label="Annuleren*" //already paid, but cancel anyway..
            color="red-ghost"
            size={buttonSize}
            onClick={() => handleAction(booking, 'cancel-booking')}
          />
        </>
      case 'completed':
        return <></>
      default:
    }
  }
  return (<></>)
}

BookingActions.propTypes = {
  booking: PropTypes.object.isRequired,
  callbackConfirm: PropTypes.func.isRequired,
  buttonSize: PropTypes.string,
}
export default BookingActions
