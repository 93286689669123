import React, {useEffect, useState} from "react";
import accountIcon from "../../assets/icons/account-white.svg";
import useUserSettingsService from "../../api/services/useUserSettingsService";
import FormButtons from "../../components/FormButtons/FormButtons";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import cloneDeep from 'lodash/cloneDeep';

const UserNotifications = () => {

  const [settings, setSettings] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [originalSettings, setOriginalSettings] = useState({})
  const {userShow, userUpdate} = useUserSettingsService()

  useEffect(() => {
    setIsLoading(true)
    userShow((settings) => {
        setIsChanged(false)
        setOriginalSettings(cloneDeep(settings))
        setSettings(settings)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
        setIsChanged(false)
        setSettings(cloneDeep(originalSettings))
      },
    )
  }, []);

  const Checkbox = (props) => {
    return (
      <div>
        <label>
          {props.prio &&
            <span className="color-red">Belangrijk: </span>
          }
          {props.label}
          <input
            type="checkbox"
            name={props.name}
            checked={settings[props.name]}
            disabled={props.locked}
            onChange={() => {
              setIsChanged(true)
              setSettings({
                ...settings,
                [props.name]: !settings[props.name],
              });
            }}
          />
        </label>
      </div>
    )
  }

  const handleConfirm = () => {
    setIsLoading(true)

    userUpdate(
      (settings) => {
        setIsChanged(false)
        setOriginalSettings(cloneDeep(settings))
        setSettings(settings)
        setIsLoading(false)
      },
      () => {
        setIsChanged(false)
        setSettings(cloneDeep(originalSettings))
        setIsLoading(false)
      },
      settings
    )
  }

  const handleCancel = () => {
    setSettings(cloneDeep(originalSettings))
    setIsChanged(false)
  }

  return (
    <div>
      {isLoading
        ?
        <Loading/>
        :
        <>
          <Button
            label="Terug naar Account"
            link={'/user/account'}
            icon={accountIcon}
          />
          <h1>Emails & Meldingen</h1>
          <h2>Account</h2>
          <Checkbox
            label="Nieuwsbrief"
            name="email_newsletter"
          />
          <Checkbox
            label="Account tips en hulp bij aanmaken"
            name="email_account_setup"
          />
          <Checkbox
            label="Emailadres aanpassen"
            name="email_change_email"
            locked={true}
          />
          <Checkbox
            label="Wachtwoord aanpassen"
            name="email_change_password"
            locked={true}
          />
          <Checkbox
            label="Nieuw wachtwoord aanvragen"
            name="email_reset_password"
            locked={true}
          />
          <Checkbox
            label="Welkom email"
            name="email_welcome_email"
            locked={true}
          />
          <h2>Berichten</h2>
          <Checkbox
            label="Nieuw bericht ontvangen"
            name="email_message_received"
            prio={true}
          />
          <h2>Boeking status updates</h2>
          <Checkbox
            label="Boeking verzoek verstuurd, uitgaande boekingen"
            name="email_status_requested_booking_requested"
          />
          <Checkbox
            label="Boeking verzoek binnengekomen, inkomende boekingen"
            name="email_status_requested_booking_received"
            prio={true}
          />
          <h4>Status betaald</h4>
          <Checkbox
            label="Boeking status is betaald, uitgaande boekingen"
            name="email_status_paid_requested"
            prio={true}
          />
          <Checkbox
            label="Boeking status is betaald, inkomende boekingen"
            name="email_status_paid_received"
            prio={true}
          />
          <h4>Status geaccepteerd</h4>
          <Checkbox
            label="Boeking geaccepteerd door partij, uitgaande boekingen"
            name="email_status_one_sided_accepted_requested"
            prio={true}
          />
          <Checkbox
            label="Boeking geaccepteerd door partij, inkomende boekingen"
            name="email_status_one_sided_accepted_received"
            prio={true}
          />
          <Checkbox
            label="Boeking status is geaccepteerd, uitgaande boekingen"
            name="email_status_two_sided_accepted_requested"
            prio={true}
          />
          <Checkbox
            label="Boeking status is geaccepteerd, inkomende boekingen"
            name="email_status_two_sided_accepted_received"
            prio={true}
          />
          <h4>Status afgewezen</h4>
          <Checkbox
            label="Boeking status afgewezen"
            name="email_status_rejected"
          />
          <h4>Status geannuleerd</h4>
          <Checkbox
            label="Boeking verzoek geannuleerd"
            name="email_status_cancel_request"
          />
          <Checkbox
            label="Boeking geannuleerd, uitgaande boekingen"
            name="email_status_canceled_booking_requested"
            prio={true}
          />
          <Checkbox
            label="Boeking geannuleerd, inkomende boekingen"
            name="email_status_canceled_booking_received"
            prio={true}
          />
          <Checkbox
            label="Geaccepteerde Boeking geannuleerd"
            name="email_status_cancel_accepted"
            prio={true}
          />
          <h4>Overzichten</h4>
          <Checkbox
            label="Booking overzicht enkele dagen voor boekingsdatums"
            name="email_booking_overview"
          />
          {/*todo implement*/}
          <Checkbox
            label="Booking herinnering na enkele dagen openstaande achtie"
            name="email_booking_reminders"
          />
          <h2>Organisaties</h2>
          <Checkbox
            // note je hebt dan nog geen account
            label="Uitnodiging van organisaties"
            name="email_company_invite"
            locked={true}
          />
          <Checkbox
            label="Gekoppeld aan organisatie"
            name="email_company_attached"
          />
          <Checkbox
            label="Ontkoppeld van organisatie"
            name="email_company_detached"
            prio={true}
          />
          <FormButtons
            isChanged={isChanged}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
          />
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </>
      }
    </div>
  )
}

export default UserNotifications
