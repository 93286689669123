import React from "react";
import {
  Media,
  Form,
  Input,
  Textarea,
  SelectBlock,
  Price,
  Fields,
  Slug,
  Options
} from "../../components/Form/Form";
import types from "../../data/types";
import industries from "../../data/industries";
import {useNavigate, useParams} from "react-router-dom";
import ProfileStatusForm from "../../components/Form/ProfileStatusForm";
import useOptionsService from "../../api/services/useOptionsService";
import useStandService from "../../api/services/useStandService";
import Availabilities from "../../components/Form/Availabilities";
import BusinessHour from "../../components/Form/BusinessHour";
import AnchorTitle from "../../components/AnchorMenu/AnchorTitle";
import AnchorIndex from "../../components/AnchorMenu/AnchorIndex";
import Location from "../../components/Form/Location";
import Checkbox from "../../components/Form/Checkbox";
import useForm from "../../hooks/useForm";
import Three from "../../components/Form/Three";
import Bool from "../../components/Form/Bool";
import salesManImage from "../../assets/images/example-images/verkoper.jpg";
import productImage from "../../assets/images/example-images/product.jpg";
import truckImage from "../../assets/images/example-images/truck.jpg";


const UserStandEdit = () => {
  const optionServiceSpotGroup = useOptionsService('spot_group_option')
  const infoServiceSpotGroup = useOptionsService('spot_group_info')
  const optionServiceStand = useOptionsService('stand_option')
  const infoServiceStand = useOptionsService('stand_info')
  const standService = useStandService()
  const navigate = useNavigate()
  const form = useForm()
  const {id} = useParams();

  return (
    <div>
      <h1>Stand, Truck, Act Instellingen</h1>
      <Form>
        <Fields
          id={id}
          form={form}
          apiService={standService}
          showDelete={true}
          deleteLabel="Hiermee verwijder je deze stand of truck:"
          deleteSuccess={() => navigate('/user/stands')}
          GAEventLabel="User stand Edit"
        >
          <AnchorIndex
            titles={[
              {
                label:'Algemeen',
                id:'general'
              },
              {
                label:'Profiel',
                id:'profile'
              },
              {
                label:'Branche & Producten',
                id:'industry'
              },
              {
                label:'Locatie', //size type map
                id:'location'
              },
              {
                label:'Afbeeldingen',
                id:'images'
              },
              {
                label:'Beschikbaarheid',
                id:'availabilities'
              },
              {
                label:'Prijs',
                id:'price'
              },
              {
                label:'Opties & Info',
                id:'options'
              },
              {
                label:'Boekingen',
                id:'bookings'
              },
            ]}
          />
          <ProfileStatusForm/>

          <AnchorTitle
            label="Algemeen"
            id="general"
          />
          <Input
            label="Naam"
            name="label"
          />
          <Input
            label="Slogan"
            name="slogan"
            placeholder="Korte pakkende zin"
          />
          <Input
            label="Actie banner"
            name="action"
            placeholder="Laat text zien voor speciale acties"
          />
          <Textarea
            label="Beschrijving"
            name="description"
            rows={8}
            placeholder="Vertel iets meer over jouw organisatie:
Wat maakt je uniek?
Waarom zouden organisaties jou boeken?"
          />

          <AnchorTitle
            label="Profiel"
            id="profile"
          />
          <Bool
            label="Openbaar Profiel: Wil je dat locaties jou profiel kunnen bekijken (via onderstaande link) & boeken?"
            name="public"
          />
          <Slug
            label="Link naar profiel"
            text="Voorbeeld: broodje-ham-van-tam"
            urlPostFix="stand"
            name="slug"
            basedOnField="label"
          />

          <AnchorTitle
            label="Branche & Producten"
            id="industry"
          />
          <SelectBlock
            label="Vink aan in welke branche jij valt met jouw stand of truck: (je kunt meerdere vakjes aankruisen)"
            name="industries_ids"
            data={industries}
          />

          <AnchorTitle
            label="Locatie"
            id="location"
          />
          <Input
            label="Lengte"
            name="length"
            type="meter"
          />
          <Input
            label="Diepte/Breedte"
            name="width"
            type="meter"
          />
          <Input
            label="Hoogte"
            name="height"
            type="meter"
          />
          <SelectBlock
            label="Met deze informatie kunnen wij jou de juiste locaties aanbieden en weten we waar jij wel en in niet in geïnteresseerd bent"
            name="types_ids"
            data={types}
          />
          <Location
            label="Klik op de map om aan te geven waar de locatie is van waar jij vertrekt. Dit wordt openbaar zichtbaar, je kunt ervoor kiezen om niet jouw exacte locatie op te geven: zet hem gerust ergens anders binnen (twee)honderd meter."
          />
          <Input
            label="Geef vervolgens aan wat de maximale afstand is die jij zou willen afleggen voor een standplaats. (enkele reis)"
            name="radius"
            type="km"
          />

          <AnchorTitle
            label="Afbeeldingen"
            id="images"
          />
          <Media
            label="1. Vooraanzicht van je stand of truck"
            name="overview_media"
            width={1600}
            height={700}
            exampleImage={truckImage}
            size="banner"
          />
          <Media
            label="2. Actie waarin de verkoper te zien is"
            name="action_media"
            width={900}
            height={450}
            exampleImage={salesManImage}
            size="normal"
          />
          <Media
            label="3. Foto van het product dat wordt verkocht (close-up)"
            name="product_media"
            width={900}
            height={450}
            exampleImage={productImage}
            size="normal"
          />

          <AnchorTitle
            label="Beschikbaarheid"
            id="availabilities"
          />
          <BusinessHour
            label="Openingstijden gebruiken"
            name="business_hour"
          />
          <Three
            label="Wat is de status van deze stand of truck?"
            name="available"
            labelYes="Elke dag beschikbaar"
            labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
            labelOther="Specifieke dag(en) ingeven"
          />
          <Availabilities
            label="Geef hier aan welke dagen je niet beschikbaar bent."
            name="availabilities"
            enabledBy={[{key:'available', value:2}]}
          />

          <AnchorTitle
            label="Prijs"
            id="price"
          />
          <Price
            // label="Prijs"
            name="price"
          />


          <AnchorTitle
            label="Opties & Info"
            id="options"
          />
          <Options
            label="Aangeboden Opties"
            name="bookable_options"
            type="stand_option"
            text="Geef hier aan welke opties jouw stand of truck eventueel nog aanbiedt. (Deze opties kunnen mee-geboekt worden door locaties / Organisaties)"
            apiService={optionServiceStand}
            showPrice={true}
          />

          <Options
            label="Gevraagde Opties"
            name="bookable_options_requested"
            type="spot_group_option"
            text="Geef hier aan welke opties jouw stand of truck nodig heeft. (Denk aan: '16a stroom p/dag' of 'kraam p/meter')"
            apiService={optionServiceSpotGroup}
            showPrice={false}
          />

          <Options
            label="Belangrijke Informatie Regels & Voorzieningen"
            name="bookable_infos"
            type="stand_info"
            text="Geef hier aan welke belangrijke informatie / voorzieningen jouw stand of truck allemaal heeft (Denk aan: 'electrische motor' of bepaalde vergunningen)"
            apiService={infoServiceStand}
            modelLabel={'informatie regel'}
            modelLabels={'informatie regels'}
            showPrice={false}
          />

          <Options
            label="Gevraagde Informatie Regels & Voorzieningen"
            name="bookable_infos_requested"
            type="spot_group_info"
            text="Welke informatie regels / voorzieningen verwacht je van de locatie? (denk aan bijgelegen parkeergelegenheid, of het niet kunnen betreden van milieu-zones)"
            apiService={infoServiceSpotGroup}
            modelLabel={'informatie regel'}
            modelLabels={'informatie regels'}
            showPrice={false}
          />

          <AnchorTitle
            label="Boekingen"
            id="bookings"
          />
          <Checkbox
            label="Maak het mogelijk voor organisaties locatie profiel om deze stand, truck of act te boeken."
            name="booking_without_spot_group"
          />

          <Textarea
            label="Boekingsvoorwaarden - zichtbaar bij het accepteren van de boeking"
            placeholder="Algemene (boekings)voorwaarden die gelden naast de standaardboekingsvoorwaarden van Standplaats.nl BV. In geval van overlap hebben de voorwaarden van Standplaats.nl BV voorrang."
            name="booking_conditions"
            rows={10}
          />
          <Textarea
            label="Boekingsinformatie - zichtbaar voor locaties nadat ze een boeking afgerond hebben van deze stand/truck/act"
            placeholder="Aanvangtijd/opbouwtijd, contactinformatie"
            name="booking_information"
            rows={8}
          />
        </Fields>
      </Form>
    </div>
  )
}

export default UserStandEdit
